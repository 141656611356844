import React, { Fragment, useEffect, useState } from "react";
import Image from "next/image";
import p7 from "../../../public/img/features-12.png";
import p8 from "../../../public/img/features-13.png";
import p9 from "../../../public/img/features-10.png";
import p11 from "../../../public/img/features-11.png";
import heart from "../../../public/img/heart.png";
import cart from "../../assets/images/cart.png";
import p10 from "../../../public/img/p10.png";
import arrow from "../../assets/images/arrow.png";
import styles from "@/styles/mobile_trending_design.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { homeLinks } from "@/data/home";
import Link from "next/link";
import ProductCard from "../product-card/product-card";
import httpClient from "@/api/http-client";
import endpoints from "@/api/endpoints";

SwiperCore.use([Navigation, Pagination]);

const Mobile_Trending_Design = () => {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    trendingDesign();
  }, []);

  const trendingDesign = async () => {
    const value = {
      slugs: JSON.parse(localStorage.getItem("recentViews") || "[]"),
    };

    const { data } = await httpClient.post(
      endpoints.home.trendingDesign(),
      value
    );
    setData(data);
  };
  return (
    <Fragment>
      {!!data.length && (
        <section className={styles.trending_design}>
          <div className="container">
            <div className={styles.trending_design_header}>
              <div className="row">
                <div className="col-12">
                  <div className={styles.h2}>
                    {homeLinks.trendingDesign.title}
                  </div>
                </div>
              </div>
              <div className={styles.trending_design_main}>
                <Swiper
                  className="swiper-navigation-black"
                  navigation={true}
                  spaceBetween={10}
                  slidesPerView={2}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 4,
                    },
                    1200: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {data.map((product: any) => (
                    <SwiperSlide key={product.id}>
                      <div className={styles.trend}>
                        <ProductCard product={product} />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {/* {data.map((v: any, i) => (

                  <div className="col-md-3" key={i}>
                    <Link href={v.link} passHref>
                      <a className={styles.trending_design_card}>
                        <Image src={v.image} alt="" width={261} height={278} />
                        <div className={styles.trending_design_wishlist}>
                          <button role="button">
                            <Image src={heart} alt="" />
                          </button>
                        </div>
                      </a>
                    </Link>
                  </div>
                ))} */}
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default Mobile_Trending_Design;
