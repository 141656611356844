import React, { Fragment } from "react";
import Image from "next/image";
import icon2 from "../../../public/img/5star/icon2.png";
import icon3 from "../../../public/img/5star/icon3.png";
import icon4 from "../../../public/img/5star/icon4.png";
import icon5 from "../../../public/img/5star/icon5.png";
import icon7 from "../../../public/img/5star/icon7.png";
import bgimage from "../../assets/images/61.png";
import advantagesheader from "@/assets/images/adv-header.jpg";
import iconadv from "@/assets/images/icon-adv.jpg";
import skv from "@/assets/images/skv.png";
import s2 from "@/assets/images/s2.png";
import s3 from "@/assets/images/s3.png";
import s4 from "@/assets/images/s4.png";
import s5 from "@/assets/images/s5.png";
import s6 from "@/assets/images/s6.png";
import s7 from "@/assets/images/s7.png";
import s8 from "@/assets/images/s8.png";
import s9 from "@/assets/images/s9.png";
import s10 from "@/assets/images/s10.png";
import s11 from "@/assets/images/s11.png";
import s12 from "@/assets/images/s12.png";
import s13 from "@/assets/images/s13.png";
import s14 from "@/assets/images/s14.png";
import styles from "@/styles/mobile_sencofiveyear.module.scss";
const Mobile_SencoFiveyear = () => {
  return (
    <Fragment>
      <div className="container">
        {/* <div className={styles.sencofiveyear}>
          <Image src={bgimage} className={styles.bgimage} alt="" />
          <div className={styles.sencofiveyear_main}>
            <div className="row">
              <div className="col-12">
                <h2 className="text-center">
                  The <br /> Promise <br /> Of <br /> Senco
                </h2>
                <h3 className="text-center">
                  With every senco
                  <br /> you get...
                </h3>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="container">
        <div className={styles.advantages_main}>
          <div className={styles.advantages_main_header}>
            <Image src={advantagesheader} alt="The Senco Advantage" />
          </div>
          <div className={styles.advantages_main_body}>
            <div className="row">
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>OVER 8 DECADES OF LEGACY</div>
                    <div className={styles.icon_adv}>
                      <Image src={iconadv} alt="OVER 8 DECADES OF LEGACY" />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      Our Legacy of trust, transparency and integrity is built
                      on its ethical business practices and focus on providing
                      customers with the purest of exquisite jewellery.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>2ND MOST TRUSTED , BRAND</div>
                    <div className={styles.icon_adv}>
                      <Image src={s2} alt="2ND MOST TRUSTED , BRAND" />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      We have won several awards and accolades, including the
                      2nd most trusted brand in the jewellery category in India
                      by TRA{"'"}S Brand Trust Report in 2020, 2021, 2022 and
                      2023
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>OUR WORLD-CLASS KARIGARI .</div>
                    <div className={styles.icon_adv}>
                      <Image src={s3} alt="OUR WORLD-CLASS KARIGARI " />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      Our jewelleries are meticulously crafted by our team of
                      karigars, using the traditional skills of world-famous
                      Bengal artistry.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>BIS HALLMARKED PUREST GOLD</div>
                    <div className={styles.icon_adv}>
                      <Image src={s4} alt="BIS HALLMARKED PUREST GOLD" />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      Rest assured, your Senco gold jewellery is of the highest
                      standard & made of BIS hallmarked gold, so you can shop
                      with confidence.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>RIGHT PRICING</div>
                    <div className={styles.icon_adv}>
                      <Image src={s5} alt="RIGHT PRICING" />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      For over 5 decades, millions of connoisseurs trust our
                      guarantee of right pricing on all products with beautiful
                      craftsmanship.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>FREE LIFETIME MAINTENANCE</div>
                    <div className={styles.icon_adv}>
                      <Image src={s6} alt="FREE LIFETIME MAINTENANCE" />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      Enjoy the lifelong facility of free servicing for all your
                      Senco jewellery, at any Senco showroom.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>
                      EXCHANGE & BUYBACK GUARANTEE
                    </div>
                    <div className={styles.icon_adv}>
                      <Image src={s7} alt="EXCHANGE & BUYBACK GUARANTEE" />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      Enjoy 0% deduction on old gold exchange & buyback facility
                      for Senco jewellery, so you can keep changing your style &
                      status quo!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>CERTIFIED GENUINE DIAMONDS</div>
                    <div className={styles.icon_adv}>
                      <Image src={s8} alt="CERTIFIED GENUINE DIAMONDS" />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      We offer only certified diamonds with buy back guarantee,
                      so you can be sure you{"'"}re buying nothing but the best
                      in the world.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>EARN REWARDING POINTS</div>
                    <div className={styles.icon_adv}>
                      <Image src={s9} alt="EARN REWARDING POINTS" />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      Earn Loyalty Points with every purchase & enjoy the
                      rewards of added benefits & cashback.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>EMI ON DIAMOND JEWELLERY</div>
                    <div className={styles.icon_adv}>
                      <Image src={s10} alt="EMI ON DIAMOND JEWELLERY" />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      Buy diamond jewellery worth ₹50,000 or above & pay later,
                      with our easy EMI facility and a lower rate of interest.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>RESPONSIBLE JEWELLER</div>
                    <div className={styles.icon_adv}>
                      <Image src={s11} alt="RESPONSIBLE JEWELLER" />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      From sourcing materials to caring for our artisans &
                      employees, we maintain the highest standards of ethics,
                      environment-friendly practices & code of conduct
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>INSURED DIAMOND JEWELLERY</div>
                    <div className={styles.icon_adv}>
                      <Image src={s12} alt="INSURED DIAMOND JEWELLERY" />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      We offer free insurance on diamond jewellery, so you can
                      dazzle stress-free!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>OMNI-CHANNEL SERVICE</div>
                    <div className={styles.icon_adv}>
                      <Image src={s13} alt="omni-channel service" />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      Our omni-channel services allow customers to access our
                      products through a variety of channels, including website,
                      customer care, physical stores, video calls and MySenco
                      mobile app.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-none-mobile-view">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h3}>
                      A world of benefits. crafted for you
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h4}>
                      EASY & REWARDING MONTHLY SAVING SCHEME
                    </div>
                    <div className={styles.icon_adv}>
                      <Image
                        src={s14}
                        alt="EASY & REWARDING MONTHLY SAVING SCHEME"
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <p>
                      Buy your dream jewellery in small monthly installments
                      with our Swarna Yojana saving scheme, tailored for your
                      convenience and earn more than bank interest.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-none-desktop-view">
                <div className={styles.card_adv_pad}>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <div className={styles.h3}>
                      A world of benefits. crafted for you
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className={styles.skv}>
                  <Image
                    src={skv}
                    alt=" A world of benefits. crafted for you"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Mobile_SencoFiveyear;
