import React, { Fragment } from "react";
import Link from "next/link";
import styles from "@/styles/mobile_lustcollection.module.scss";
import { homeLinks } from "@/data/home";
const Mobile_LustCollection = () => {
  return (
    <Fragment>
      <section className="container">
        <div className={styles.lustcollection}>
          <div className={styles.videoResponsive}>
            <div className="row">
              <div
                dangerouslySetInnerHTML={{
                  __html: homeLinks.lustCollection.youtube_iframe,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="container">
        <div className="col-md-12 text-center">
          <Link href={homeLinks.lustCollection.link} passHref>
            <a className={styles.btn_get_started}>View All Collections </a>
          </Link>
        </div>
      </div> */}
    </Fragment>
  );
};

export default Mobile_LustCollection;
