import React, { Fragment } from "react";
import styles from "@/styles/mobile_subscribe.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { toast } from "react-toastify";

const Mobile_Subscribe = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email format"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (values.email.length) {
        try {
          const { data } = await httpClient.post(endpoints.auth.subscribe(), {
            ...values,
            type: "senco_website",
          });
          toast(data.message, {
            type: "success",
          });
          resetForm();
        } catch (error: any) {
           if (error.response?.data?.message) {
             toast(error.response.data.message as string, {
               type: "error",
             });
           } else {
             toast("Something went wrong! Try again!", {
               type: "error",
             });
           }
        }
      }
    },
  });
  return (
    <Fragment>
      <section className={styles.subscribe_main_full}>
        <div className="container">
          <hr />
          <div className={styles.subscribe_main}>
            <div className="row mt-3 pb-4">
              <div className="col-md-6">
                <div className="middle-section">
                  <div className={styles.h4}>
                    Stay <span> in touch</span> <br /> & get latest
                    <br /> updates and offers
                    <br />
                    and design
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <form
                  className="mt-0 pt-2"
                  action=""
                  onSubmit={formik.handleSubmit}
                >
                  <div className="row">
                    <input
                      className={styles.form_input}
                      type="email"
                      placeholder="YOUR EMAIL"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.errors.email ? (
                      <small className="text-white">
                        {formik.errors.email}
                      </small>
                    ) : null}
                  </div>

                  <button
                    role="button"
                    aria-label="SUBSCRIBE"
                    className={styles.btn_get_started1}
                    type="submit"
                  >
                    SUBSCRIBE <i className="bi bi-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>

          <hr />
        </div>
      </section>
    </Fragment>
  );
};

export default Mobile_Subscribe;
