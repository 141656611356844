import React, { Fragment } from "react";
import Link from "next/link";
import Image from "next/image";
import features17 from "../../../public/img/features-17.png";
import features18 from "../../../public/img/features-18.png";
import features19 from "../../../public/img/features-19.png";
import features20 from "../../../public/img/features-20.png";
import features21 from "../../../public/img/features-21.png";
import features22 from "../../../public/img/features-22.png";
import styles from "@/styles/mobile_featured_customers.module.scss";
import { homeLinks } from "@/data/home";
const Mobile_Featured_Customers = () => {
  return (
    <Fragment>
      <section className={styles.featured_customers}>
        <div className="container">
          <div className={styles.featured_customers_header}>
            <div className={styles.h2}>{homeLinks.freaturedCustomer.title}</div>
          </div>
          <div className="row  mt-4 justify-content-center">
            {homeLinks.freaturedCustomer.images.map((v: any, i) => (
              <div className="col-6 col-sm-6 mb-3" key={i}>
                <div className=" text-center">
                  <Link href={v.link} passHref>
                    <div className="w-100">
                      <Image
                        src={v.image}
                        className="img-fluid"
                        alt="YOU X SENCO"
                        width={233}
                        height={237}
                      />
                    </div>
                  </Link>
                </div>
              </div>
            ))}

            {/* <div className="col-md-12  text-center">
              <p>
                {homeLinks.freaturedCustomer.footer.desc}
                <Link href={homeLinks.freaturedCustomer.footer.link}>
                  <a className={styles.btn_more}>
                    {homeLinks.freaturedCustomer.footer.linkdesc}
                  </a>
                </Link>
              </p>
            </div> */}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Mobile_Featured_Customers;
