import React, { Fragment, useRef, useState } from "react";

import { Scrollbar } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import Image from "next/image";
import p1 from "../../assets/images/p1.png";
import p3 from "../../assets/images/p3.png";
import p2 from "../../assets/images/p2.png";
import percent from "../../assets/images/percent.png";

import styles from "@/styles/mobile_shop_by_category.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { homeLinks } from "@/data/home";
import Link from "next/link";
SwiperCore.use([Navigation, Pagination]);

const Mobile_Shop_By_Category = () => {
  return (
    <Fragment>
      <div className={styles.mobile_shop_by_category}>
        <div className="container">
          <h1 className={styles.shopbycategoryheader_head}>
            {homeLinks.shopByCategory.title}
          </h1>
          <div className={styles.mobile_shop_by_category_slider}>
            <Swiper
              className="swiper-navigation-black pb-4"
              spaceBetween={0}
              slidesPerView={2}
              pagination={false}
              navigation={{
                prevEl: ".swiper-button-prev-1",
                nextEl: ".swiper-button-next-1",
              }}
              modules={[Pagination, Navigation]}
              centeredSlides={false}
              breakpoints={{
                1199: {
                  slidesPerView: 6,
                },
              }}
            >
              {homeLinks.shopByCategory.sliders.map((v: any, i) => (
                <SwiperSlide key={i}>
                  <div className={styles.mobile_shop_by_category_card}>
                    <Link href={v.link} passHref>
                      <div className="w-100">
                        <Image
                          src={v.image}
                          alt="Shop by Category"
                          width={134}
                          height={140}
                        />
                        <p>{v.name}</p>
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <div
              className={`${
                styles.swiper_button_prev_1
              } ${"swiper-button-prev-1 bi bi-arrow-left-circle"}`}
            ></div>
            <div
              className={`${
                styles.swiper_button_next_1
              } ${"swiper-button-next-1 bi bi-arrow-right-circle"}`}
            ></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Mobile_Shop_By_Category;
