import React, { Fragment, useEffect, useState } from "react";
import styles from "@/styles/best_sellers.module.scss";
import { homeLinks } from "@/data/home";
import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import ProductCard from "../product-card/product-card";
import arrow from "@/assets/images/arrow.png";
import Image from "next/image";
import Link from "next/link";

const Best_Sellers = () => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    loadCustomerFavourites();
  }, []);

  const loadCustomerFavourites = async () => {
    const { data } = await httpClient.get(endpoints.home.customerFavourites(), {
      params: { limit: 4 },
    });
    setData(data);
  };
  return (
    <Fragment>
      {!!data.length && (
        <section className={styles.best_sellers}>
          <div className="container">
            <div className={styles.best_sellersheader}>
              <div className="row">
                <div className="col-md-6">
                  <div className={styles.h2}>{homeLinks.bestSeller.title}</div>
                </div>
                <div className="col-md-6">
                  <Link href="/customers-favourite" passHref>
                    <div className={styles.btn_get_started}>
                      SEE ALL Products
                    </div>
                  </Link>
                </div>
              </div>
              <div className={styles.best_sellers_main}>
                <div className="row">
                  {data.slice(0, 20).map((v: any, ind: any) => (
                    <div className="col-md-3" key={ind}>
                      <ProductCard product={v} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default Best_Sellers;
