import React, { Fragment } from "react";
import Image from "next/image";
import withicon from "../../assets/images/with-icon.png";
import withicon1 from "../../assets/images/with-icon1.png";
import withicon3 from "../../assets/images/with-icon3.png";
import withicon4 from "../../assets/images/with-icon4.png";
import withicon5 from "../../assets/images/with-icon5.png";
import withicon6 from "../../assets/images/with-icon6.png";
import withicon7 from "../../assets/images/with-icon7.png";
import withicon8 from "../../assets/images/with-icon8.png";
import prodd1 from "../../assets/images/prodd1.png";
import prodd2 from "../../assets/images/prodd2.png";
import prodd3 from "../../assets/images/prodd3.png";
import prodd4 from "../../assets/images/prodd4.png";
import prodd5 from "../../assets/images/prodd5.png";
import prodd6 from "../../assets/images/prodd6.png";
import prodd7 from "../../assets/images/prodd7.png";
import prodd8 from "../../assets/images/prodd8.png";

import styles from "@/styles/mobile_with_every_product.module.scss";
const Mobile_With_Every_Product = () => (
  <Fragment>
    <section>
      <div className={styles.with_every_product}>
        <div className="container">
          <div className="row d-flex justify-content-center mt-0 border_bottom_1 pb-4">
            {/* <div className={styles.col_14}>
              <div className={styles.with_icon}>
                <Image src={prodd1} alt="Senco five star Promise" className="img-fluid" />
                <p>Senco five star Promise</p>
              </div>
            </div> */}
            <div className={styles.col_14}>
              <div className={styles.with_icon}>
                <Image
                  src={prodd3}
                  alt="Zero Deduction Gold Exchange"
                  className="img-fluid"
                />
                <p>Zero Deduction Gold Exchange</p>
              </div>
            </div>

            <div className={styles.col_14}>
              <div className={styles.with_icon}>
                <Image src={prodd3} alt="Easy Exchange" className="img-fluid" />
                <p>Easy Exchange</p>
              </div>
            </div>
            <div className={styles.col_14}>
              <div className={styles.with_icon}>
                <Image
                  src={prodd4}
                  alt="Assured Lifetime Maintenance"
                  className="img-fluid"
                />
                <p>Assured Lifetime Maintenance</p>
              </div>
            </div>
            <div className={styles.col_14}>
              <div className={styles.with_icon}>
                <Image
                  src={prodd5}
                  alt="Tested & Certified Diamonds"
                  className="img-fluid"
                />
                <p>Tested & Certified Diamonds</p>
              </div>
            </div>
            <div className={styles.col_14}>
              <div className={styles.with_icon}>
                <Image
                  src={prodd6}
                  alt="BIS Hallmarked Pure Gold"
                  className="img-fluid"
                />
                <p>BIS Hallmarked Pure Gold</p>
              </div>
            </div>
            <div className={styles.col_14}>
              <div className={styles.with_icon}>
                <Image
                  src={prodd7}
                  alt="Guaranteed Buyback"
                  className="img-fluid"
                />
                <p>Guaranteed Buyback</p>
              </div>
            </div>
            <div className={styles.col_14}>
              <div className={styles.with_icon}>
                <Image
                  src={prodd8}
                  alt="7 Days Retun Policy"
                  className="img-fluid"
                />
                <p>7 Days Retun Policy</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
);

export default Mobile_With_Every_Product;
