import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import Image from "next/image";
import Link from "next/link";
import styles from "@/styles/live_banner.module.scss";
import React, { useEffect, useState } from "react";

function LiveBanner() {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const response = await httpClient.get(endpoints.home.live());
    setData(response.data);
  };

  if (!data || !data.is_active) return <></>;

  return (
    <section>
      <div className="container">
        <div className={styles.live_banner_image}>
          <Link href={"/live"} passHref>
            <div className="w-100">
              <Image
                src={data.image}
                alt="Gossip silver fashion jewellery"
                height={226}
                width={1116}
              />
              <div className="clearfix"></div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default LiveBanner;
