import React, { Fragment } from "react";
import Image from "next/image";
import arrow from "../../assets/images/arrow.png";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "@/styles/mobile_vivah_collection.module.scss";
import { homeLinks } from "@/data/home";
import Link from "next/link";
import LiteYouTubeEmbed from "react-lite-youtube-embed";

const Mobile_Vivah_Collection = () => {
  return (
    <Fragment>
      <section>
        <div className={styles.vivah_collection}>
          <div className=" container">
            <div className={styles.vivah_collection_header}>
              <div className={styles.vivah_collection_header_text}>
                {homeLinks.vivahCollection.title}
              </div>
            </div>
            <div className={styles.vivah_collection_main}>
              <div className={styles.videoResponsive}>
                <div className="row">
                  <LiteYouTubeEmbed
                    id={homeLinks.vivahCollection.youtube_iframe_id}
                    title="Vivah Collection"
                    webp={true}
                    noCookie={true}
                    poster="maxresdefault"
                  />
                </div>
              </div>

              <Swiper
                className="swiper-navigation"
                navigation={true}
                spaceBetween={20}
                slidesPerView={1}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                  1200: {
                    slidesPerView: 4,
                  },
                }}
              >
                {homeLinks.vivahCollection.images.map((v: any, i) => (
                  <SwiperSlide key={i}>
                    <Link href={v.link} passHref>
                      <div className={styles.vivah_collection_card_1}>
                        <Image
                          src={v.image}
                          alt="vivaha collection"
                          width={261}
                          height={347}
                        />
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}

                <SwiperSlide>4</SwiperSlide>
              </Swiper>
            </div>
            <div className="text-center">
              <Link href={homeLinks.vivahCollection.link} passHref>
                <div className={styles.btn_get_started}>
                  EXPLOER{" "}
                  <Image src={arrow} className="img-fluid" alt="EXPLOER" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Mobile_Vivah_Collection;
