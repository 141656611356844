import React from "react";
import { wrapper } from "@/store";
import {
  setIgnoreCommonTitle,
  setMeta,
  setOg,
  setPath,
  setSchema,
  setTitle,
} from "@/store/slices/helmet";
import { isMobile } from "@/utils/deviceChecker";
import dynamic from "next/dynamic";
import { ReactElement, useEffect } from "react";
import Best_Sellers from "../components/home/best_sellers";
import Express from "../components/home/express_delivery";
import Featured_Customers from "../components/home/featured_customers";
import Men_Collections from "../components/home/men_collections";
import Mobile_Banner from "../components/home/mobile_banner";
import Mobile_Best_Sellers from "../components/home/mobile_best_sellers";
import Mobile_Collection from "../components/home/mobile_collection";
import Mobile_Featured_Customers from "../components/home/mobile_featured_customers";
import Mobile_LustCollection from "../components/home/mobile_lustcollection";
import Mobile_Men_Collections from "../components/home/mobile_men_collections";
import Mobile_New_Arrivals from "../components/home/mobile_new_arrivals";
import Mobile_Perfect_Gift from "../components/home/mobile_perfect_gift";
import Mobile_Senco_Exclusive from "../components/home/mobile_senco_exclusive";
import Mobile_SencoFiveyear from "../components/home/mobile_sencofiveyear";
import Mobile_SencoStory from "../components/home/mobile_sencostory";
import Mobile_Shop_By_Category from "../components/home/mobile_shop_by_category";
import Mobile_Shopbybudget from "../components/home/mobile_shopbybudget";
import Mobile_Subscribe from "../components/home/mobile_subscribe";
import Mobile_Trending_Design from "../components/home/mobile_trending_design";
import Mobile_Vivah_Collection from "../components/home/mobile_vivah_collection";
import Mobile_With_Every_Product from "../components/home/mobile_with_every_product";
import Senco_Exclusive from "../components/home/senco_exclusive";
import SencoFiveyear from "../components/home/sencofiveyear";
import SencoStory from "../components/home/sencostory";
import Subscribe from "../components/home/subscribe";
import Trending_Design from "../components/home/trending_design";
import Vivah_Collection from "../components/home/vivah_collection";
import With_Every_Product from "../components/home/with_every_product";
import Layout from "../layout/layout";

import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import Hero from "@/components/home/hero";
import LiveBanner from "@/components/home/live-banner";
import { syteTrackEvent } from "@/lib/syte";
import { selectAuth } from "@/store/slices/auth";
import { fetchPageBySlug } from "@/store/slices/page";
import { useSelector } from "react-redux";

const ExpressDeliveryComponent = dynamic(
  () => import("@/components/home/expressdelivery")
);
const OtherProductsComponent = dynamic(
  () => import("@/components/home/otherProducts")
);
const ShopByCategoryComponent = dynamic(
  () => import("@/components/home/shopbycategory")
);
const ShopByBudgetComponent = dynamic(
  () => import("@/components/home/shopbybudget")
);
const CollectionComponent = dynamic(
  () => import("@/components/home/collection")
);
const LustCollectionComponent = dynamic(
  () => import("@/components/home/lustcollection")
);
const PerfectGiftComponent = dynamic(
  () => import("@/components/home/perfect_gift")
);
const NewArrivalsComponent = dynamic(
  () => import("@/components/home/new_arrivals")
);

type PageProps = {
  isMobile: boolean;
  banners: any[];
};

const Home = ({ isMobile, banners }: PageProps) => {
  const authState = useSelector(selectAuth);

  useEffect(() => {
    if (!authState.isUserLoading) {
      syteTrackEvent({
        name: "fe_page_view",
        tag: "ecommerce",
        osh_id: authState.user?.uuid,
      });
    }
  }, [authState.isUserLoading, authState.user?.uuid]);

  if (isMobile) {
    return (
      <>
        <Mobile_Banner banners={banners} />
        <Express />
        <Mobile_Shop_By_Category />
        <Mobile_Shopbybudget />
        <Mobile_Collection />
        <Mobile_LustCollection />
        <Mobile_Perfect_Gift />
        <Mobile_New_Arrivals />
        <Mobile_Men_Collections />
        <Mobile_Senco_Exclusive />
        <Mobile_Best_Sellers />
        <Mobile_Trending_Design />
        <Mobile_Vivah_Collection />
        <Mobile_SencoFiveyear />
        <Mobile_With_Every_Product />
        <Mobile_Subscribe />
        <Mobile_SencoStory />
        <Mobile_Featured_Customers />
      </>
    );
  }
  return (
    <>
      <Hero banners={banners} />
      <ExpressDeliveryComponent />
      <LiveBanner />
      <OtherProductsComponent />
      <ShopByCategoryComponent />
      <ShopByBudgetComponent />
      <CollectionComponent />
      <LustCollectionComponent />
      <PerfectGiftComponent />
      <NewArrivalsComponent />
      <Men_Collections />
      <Senco_Exclusive />
      <Best_Sellers />
      <Trending_Design />
      <Vivah_Collection />
      <SencoFiveyear />
      <With_Every_Product />
      <Subscribe />
      <SencoStory />
      <Featured_Customers />
    </>
  );
};

Home.getLayout = (page: ReactElement) => {
  return <Layout>{page}</Layout>;
};

export const getServerSideProps: any = wrapper.getServerSideProps(
  (store) =>
    async ({ req, res, params, resolvedUrl }) => {
      store.dispatch(setPath(resolvedUrl));
      const [{ payload: page }, { data: banners }] = await Promise.all([
        store.dispatch(fetchPageBySlug("home")),
        httpClient.get(endpoints.home.banner()),
      ]);

      if (page) {
        store.dispatch(setTitle(page.title));
        store.dispatch(
          setMeta({
            title: page.meta_title,
            description: page.meta_description,
            keywords: page.meta_keywords,
          })
        );
        store.dispatch(
          setOg({
            type: "website",
            url: `${process.env.NEXT_PUBLIC_APP_URL}${resolvedUrl}`,
            title: page.meta_title,
            description: page.meta_description,
            image:
              banners.filter((x: any) => x.device === "mobile")[0]?.image || "",
          })
        );
        if (page.title) store.dispatch(setIgnoreCommonTitle(true));
        if (page.schema) store.dispatch(setSchema(page.schema));
      }

      res.setHeader(
        "Cache-Control",
        "public, s-maxage=60, stale-while-revalidate=99"
      );
      return {
        props: {
          isMobile: isMobile(req),
          banners,
        },
      };
    }
);

export default Home;
