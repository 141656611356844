import React, { Fragment } from "react";
import Link from "next/link";
import Image from "next/image";
import features17 from "../../../public/img/features-17.png";
import features18 from "../../../public/img/features-18.png";
import features19 from "../../../public/img/features-19.png";
import features20 from "../../../public/img/features-20.png";
import features21 from "../../../public/img/features-21.png";
import features22 from "../../../public/img/features-22.png";
import styles from "@/styles/featured_customers.module.scss";
import { homeLinks } from "@/data/home";
const Featured_Customers = () => {
  return (
    <Fragment>
      <section className={styles.featured_customers}>
        <div className="container">
          <div className={styles.featured_customers_header}>
            <div className={styles.h2}>{homeLinks.freaturedCustomer.title}</div>
            {/* <p>{homeLinks.freaturedCustomer.subTitle}</p> */}
          </div>
          <div className="row justify-content-center">
            {homeLinks.freaturedCustomer.images.map((v: any, i) => (
              <div className="col-lg-4 col-12" key={i}>
                <div className="mb-3 text-center">
                  <Link href={v.link} passHref>
                    <Image
                      src={v.image}
                      className="img-fluid"
                      alt="YOU X SENCO"
                      width={261}
                      height={302}
                    />
                  </Link>
                </div>
              </div>
            ))}

            {/* <div className="col-md-12  mt-3 mb-3 text-center">
              <p>
                {homeLinks.freaturedCustomer.footer.desc}
                <Link href={homeLinks.freaturedCustomer.footer.link}>
                  <a className={styles.btn_more}>
                    {homeLinks.freaturedCustomer.footer.linkdesc}
                  </a>
                </Link>
              </p>
            </div> */}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Featured_Customers;
