import React, { Fragment } from "react";
import Image from "next/image";
import perfect from "../../assets/images/perfect.png";
import perfect1 from "../../assets/images/perfect1.png";
import perfect2 from "../../assets/images/perfect2.png";
import perfect3 from "../../assets/images/perfect3.png";
import perfect4 from "../../assets/images/perfect4.png";
import offer from "../../assets/images/offer.jpg";
import arrow from "../../assets/images/arrow.png";
import styles from "@/styles/mobile_perfect_gift.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { homeLinks } from "@/data/home";
import Link from "next/link";

const Mobile_Perfect_Gift = () => {
  return (
    <Fragment>
      <section className={styles.perfect_gift}>
        <div className="container">
          <div className={styles.perfect_gift_header}>
            <h4>{homeLinks.perfectGift.title}</h4>
          </div>
          <Swiper
            className="swiper-navigation botted"
            navigation={false}
            spaceBetween={0}
            slidesPerView={1}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: false,
            }}
            grabCursor={false}
            centeredSlides={true}
          >
            {homeLinks.perfectGift.images.map((v: any, i) => (
              <SwiperSlide key={i}>
                <Link href={v.link} passHref>
                  <div className="w-100">
                    <div className={styles.gift_jwellery}>
                      <Image
                        src={v.image}
                        className="img-fluid"
                        alt={v.name}
                        width={306}
                        height={468}
                      />

                      <div className={styles.gift_text}>
                        <div className={styles.h3_sub}>{v.name}</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="text-center pt-4">
            <a
              href={homeLinks.perfectGift.link}
              className={styles.btn_get_started}
            >
              Explore All Gifts
              <Image src={arrow} className="img-fluid" alt="" />
            </a>
          </div>
        </div>
      </section>
      {/* <div className="container mt-4 mb-0">
        <hr />
        <div className={styles.offer}>
          <Image src={offer} alt="" />
        </div>
      </div> */}
    </Fragment>
  );
};

export default Mobile_Perfect_Gift;
